import { Box, Button, Container, Image, Text } from "@chakra-ui/react";
import React from "react";
import { openWhatsApp } from "../../utils";
import SectionTitle from "../SectionTitle";

import ServiceItem from "./ServiceItem";

import ALL_SERVICES from './services'

const Services: React.FC = () => {
  return (
    <Box pt="60px" pb="60px" bg="#FFF4F6">
      <Container maxW="container.xxl">
        <SectionTitle
          title="Como posso ajudar você?"
          subtitle="Serviços"
          description="Toque em “Saiba mais” para entender mais sobre como funciona na prática."
        />
        <Box
          display="flex"
          gap="20px"
          mt="60px"
          flexWrap="wrap"
          justifyContent="center"
        >
          {ALL_SERVICES.map((service) => (
            <ServiceItem key={service.title} {...service} />
          ))}
        </Box>
        <Box display="flex" justifyContent="center" mt="60px">
          <Button
            size="md"
            bg="primary.main"
            color="white"
            textTransform="uppercase"
            _hover={{ bg: "primary.main" }}
            _active={{ bg: "primary.main2" }}
            onClick={openWhatsApp}
          >
            Agendar uma Consulta
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Services;
