import { Box, Button, Container, Image, Text } from "@chakra-ui/react";
import React from "react";
import LogoIcon from "../../assets/images/logo-icon-footer.svg";
import RigelSoftwares from "../../assets/images/rigel-softwares.svg";

const FooterDesktop = () => {
  return (
    <Box bg="#FFF4F6">
      <Box w="100%" bg="#E7D09A" borderTopRadius="25px">
        <Container
          maxW="container.xxl"
          padding="12px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          color="primary.main"
        >
          <Image
            src={LogoIcon}
            objectFit="cover"
            htmlWidth="120px"
            htmlHeight="auto"
          />
          <Text color="#7E6935" fontSize="18px" lineHeight="18px">
            &copy; 2022. Todos os direitos reservados.
          </Text>
          <Button
            variant="link"
            color="primary.main"
            _hover={{ textDecoration: "none" }}
            _active={{ color: "primary.main" }}
            onClick={() => {
              window.open('https://rigelsoftwares.com')
            }}
          >
            <Box display="flex" alignItems="center" gap="12px">
              <Text color="#7E6935" fontWeight="normal">
                Desenvolvido por
              </Text>
              <Image
                src={RigelSoftwares}
                objectFit="cover"
                htmlWidth="120px"
                htmlHeight="auto"
              />
            </Box>
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default FooterDesktop;
