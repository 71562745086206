import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

type Props = {
  title: string;
  subtitle?: string;
  description?: string;
};

const SectionTitle = ({ title, subtitle, description }: Props) => {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const titleSize = isMobile
    ? { lineHeight: "27px", fontSize: "23px" }
    : { lineHeight: "56px", fontSize: "40px" };
  const subtitleSize = isMobile
    ? { lineHeight: "18px", fontSize: "16px" }
    : { lineHeight: "28px", fontSize: "24px" };
  const descriptionSize = isMobile
    ? { lineHeight: "16px", fontSize: "14px" }
    : { lineHeight: "21px", fontSize: "18px" };
  return (
    <Box>
      {!!subtitle && (
        <Heading
          mb="12px"
          color="secondary.main3"
          as="h2"
          fontWeight={400}
          textTransform="uppercase"
          textAlign="center"
          {...subtitleSize}
        >
          {subtitle}
        </Heading>
      )}
      <Heading
        mb="12px"
        color="#3E3727"
        as="h1"
        fontWeight={600}
        textAlign="center"
        {...titleSize}
      >
        {title}
      </Heading>
      {!!description && (
        <Text
          textAlign="center"
          color="#3E3727"
          {...descriptionSize}
        >
          {description}
        </Text>
      )}
    </Box>
  );
};

export default SectionTitle;
