import { Box, BoxProps, Button, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { AiOutlineWhatsApp as WhatsAppIcon } from "@react-icons/all-files/ai/AiOutlineWhatsApp";
import { AiOutlineInstagram as InstagramIcon } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { AiOutlineMail as MailIcon } from "@react-icons/all-files/ai/AiOutlineMail";
import { openInstagram, createMailtoLink, openWhatsApp } from "../../utils";


const SocialNetworkButtons = (props: BoxProps) => {
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  const buttonPadding = isMobile ? '8px' : '12px';
  return (
    <Box
      mt="16px"
      pl="40px"
      pr="40px"
      display="flex"
      justifyContent="space-around"
      {...props}
    >
      <Button
        variant="link"
        bg="secondary.main"
        color="white"
        padding={buttonPadding}
        borderRadius="99"
        onClick={openWhatsApp}
        title="Enviar mensagem no WhatsApp"
      >
        <WhatsAppIcon size={32} />
      </Button>
      <Button
        variant="link"
        bg="secondary.main"
        color="white"
        padding={buttonPadding}
        borderRadius="99"
        onClick={openInstagram}
        title="Abrir o Instagram"
      >
        <InstagramIcon size={32} />
      </Button>
      <Button
        variant="link"
        bg="secondary.main"
        color="white"
        padding={buttonPadding}
        borderRadius="99"
        as="a"
        href={createMailtoLink()}
      >
        <MailIcon size={32} />
      </Button>
    </Box>
  );
};

export default SocialNetworkButtons;
