import React from "react";

import TerapiaTranspessoalSistemicaImage from "../../assets/images/terapia-transpessoal-sistemica.svg";
import ConstelacaoFamiliarImage from "../../assets/images/constelacao-familiar.svg";
import YogaImage from "../../assets/images/yoga.svg";
import TaroTerapeuticoImage from "../../assets/images/taro-terapeutico.svg";
import MapaTarologicoEmpresarialImage from "../../assets/images/mapa-tarologico-empresarial.svg";
import MapaTarologicoPessoalImage from "../../assets/images/mapa-tarologico-pessoal.svg";
import TaroLivreImage from "../../assets/images/taro-livre.svg";
import { Text, TextProps } from "@chakra-ui/react";

const textProps = {
  fontSize: "18px",
  lineHeight: "18px",
  textAlign: "justify",
  color: "secondary.main3",
  mb: "12px",
} as TextProps;

const Paragraph = (props: TextProps) => (
  <Text {...{ ...textProps, ...props }} />
);

export default [
  {
    title: "Terapia Transpessoal Sistêmica",
    image: TerapiaTranspessoalSistemicaImage,
    description: (
      <React.Fragment>
        <Paragraph>
          Sessões de Psicoterapia – Abordagem Terapia Transpessoal Sistêmica
          (TTS) – individual ou casal - Online
        </Paragraph>
        <Paragraph>
          A Terapia Transpessoal Sistêmica - TTS, é uma abordagen terapêutica
          que foi criada por Jordan Campos. Ela surgiu da junção de várias
          correntes filosóficas e psicológicas.
        </Paragraph>
        <Paragraph>
          Nosso sistema nervoso é bem complexo e as vezes tem reações que fogem
          e muito ao nosso controle. Quando passamos por uma situação de perigo
          ou estresse, nosso cérebro nos prepara para enfrentar ou fugir da tal
          situação. Muitas vezes, não fazemos nenhuma das duas opções e
          paralizamos. Essa paralização chamamos de Experiência Não Assimilada
          (ENA). Em outras palavras, é uma experiência que não foi digerida pelo
          sistema nervoso. A TTS tem como base a localização das ENAs em um dos
          seis setores macros: Vida pós-parto, Vida intrauterina, Vidas passadas
          biológicas (nossos antepassados), Existência infinita,
          multidimensional e atemporal, Invasores energéticos e Religação com o
          divino.
        </Paragraph>
        <Paragraph>
          Na prática, localiza-se o conflito e organiza-se o que foi vivido para
          o nosso sistema nervoso. Na TTS são oferecidos ao paciente diversas
          ferramentas para chegar a esse objetivo.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Constelação Familiar",
    image: ConstelacaoFamiliarImage,
    description: (
      <React.Fragment>
        <Paragraph>
          Através da Constelação, podemos observar o que está por trás dos
          problemas, e muitas vezes enxergar com clareza o que nem sempre está
          claro.
        </Paragraph>
        <Paragraph>
          “A Constelação Familiar é uma ferramenta fenomenológica não científica
          que ganhou fama pelas mãos do terapeuta alemão Bert Hellinger (1925
          –2019). O seu principal objetivo é revelar dinâmicas comportamentais
          que podem estar por trás de problemáticas como sintomas, vícios,
          conflitos familiares, de relacionamentos interpessoais e amorosos. É
          uma prática de intervenção complementar, integrativa, que não exclui
          outros tratamentos medicamentosos e terapêuticos em nenhuma hipótese.”
          Terena Cardoso
        </Paragraph>
        <Paragraph>
          Para Constelar comigo, basta agendar uma entrevista inicial para
          depois agendarmos a Constelação de fato.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Yoga Dance",
    image: YogaImage,
    description: (
      <React.Fragment>
        <Paragraph>
          Yoga Dance é uma prática livre, que une os benefícios da dança e da
          Yoga, potencializando bastante nosso caminho de autoconhecimento. As
          aulas costumam ser dinâmicas e muito centradas na participação ativa
          de cada aluno. Isso porque a Yoga Dance traz para o praticante a
          possibilidade de descobrir a sua própria dança, ou seja, como a música
          em seus diversos gêneros mobiliza cada um dos nossos centros
          energéticos (chakra).
        </Paragraph>
        <Paragraph>
          Através dos movimentos que surgem nas aulas, nos expandimos e
          relaxamos pontos de conflito e tensão que podem ser o epicentro das
          nossas angústias do dia-a-dia. A prática nos aproxima ainda mais do
          bem-estar e, consequentemente, de um enfrentamento mais fortalecido
          para as demandas da vida.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Tarô Terapeutico",
    image: TaroTerapeuticoImage,
    description: (
      <React.Fragment>
        <Paragraph>
          O Tarô Terapêutico te mostra aquilo que você não consegue enxergar!
        </Paragraph>
        <Paragraph>
          Longe da visão adivinhatória, este método tem por princípio de que
          somos plenamente responsáveis pela vida que temos. O Tarô terapêutico
          possibilita ao cliente entender melhor o que acontece com ele. Ajuda a
          trazer à luz conteúdos inconscientes, clareando o que está impedindo
          ou facilitando os acontecimentos. Ao clarear o que estava oculto,
          ajuda no autoconhecimento e na tomada de decisões mais assertivas. O
          funcionamento do Tarô Terapêutico se apoia nos conceitos de arquétipo,
          inconsciente coletivo e sincronicidade., explicados por Carl Gustav
          Jung. Após estudar vários sistemas simbólicos, Jung percebeu que há
          certas imagens, com diferentes roupagens, nas mais diversas culturas.
          Essas “imagens primordiais” foram chamadas de arquétipos. Ao perceber
          que todas as culturas que estudou apresentam esses arquétipos, mesmo
          que nunca houvessem tido contato umas com as outras, Jung criou a
          ideia de que existe um inconsciente coletivo, repositório de
          arquétipos e experiências arquetípicas comum aos seres humanos.
        </Paragraph>
        <Paragraph>
          Jung também reparou que muito do que se chamava de coincidência, na
          verdade, trazia conteúdos significativo para os envolvidos. Quem nunca
          passou por uma coincidência? O telefone toca ou você encontrar a
          pessoa em que estava pensando? Para Jung, não se tratava de
          coincidências, mas de eventos guiados pelas leis de sincronicidade. No
          caso do Tarô Terapêutico, os conteúdos inconscientes do cliente
          “atraem” as cartas que expressam e ajudam a clarear os significados do
          que ele está vivendo. Assim, ajudam a observar de fora o que está
          dentro. Não é adivinhação, ou misticismo.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Mapa Tarológico Empresarial",
    image: MapaTarologicoEmpresarialImage,
    description: (
      <React.Fragment>
        <Paragraph>
          Tenha um posicionamento detalhado para uma empresa e ou negócio!
        </Paragraph>
        <Paragraph>
          O Mapa Tarológico Empresarial é realizado através de uma mandala
          composta de 13 cartas. Ele é como um check-up da vida da sua empresa,
          permitindo uma visão ampla em diversos aspectos do seu negócio.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Mapa Tarológico Pessoal",
    image: MapaTarologicoPessoalImage,
    description: (
      <React.Fragment>
        <Paragraph>
          O Mapa Tarológico Pessoal é realizado através de uma mandala composta
          de 13 cartas. Ele é como um check-up da sua vida, permitindo uma visão
          ampla em diversos aspectos. Com ele, acessamos o inconsciente coletivo
          e observamos seu campo energético. Assim, com a ajuda das cartas,
          reconhecemos padrões, atitudes e ações com um outro olhar, graças a
          sincronicidade.
        </Paragraph>
        <Paragraph>
          O Tarô Terapêutico é um poderoso aliado para o Autoconhecimento e nos
          ajuda a tomar decisões de maneira mais consciente e assertiva em
          nossas vidas. É uma oportunidade única de conexão profunda com nosso
          próprio Eu.
        </Paragraph>
      </React.Fragment>
    ),
  },
  {
    title: "Tarô Livre",
    image: TaroLivreImage,
    description: (
      <React.Fragment>
        <Paragraph>
          Nessa sessão de Tarô terapêutico, você poderar ficar livre para
          realizar perguntas desejadas sobre alguma área.
        </Paragraph>
      </React.Fragment>
    ),
  },
];
