import { Box, Button, Center, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import ClaraBrazSpellImage from "../../assets/images/clara-braz-spell.svg";
import ClaraBrazAboutImage from '../../assets/images/clara-braz-about.png';
import SocialNetworkButtons from "../SocialNetworkButtons";

const MobileAbout = () => {
  return (
    <Box bg="#FFF4F6" pt="40px" pb="60px">
      <Box
        background="linear-gradient(90deg, #E7D09A 19.06%, #F8EBCE 51.35%, #E7D09A 100%);"
        borderRadius="30px"
        padding="16px"
        width="100%"
        maxW="90%"
        margin="0 auto"
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" gap="20px">
          <Box width="100%" maxW="35%" minW="35%">
            <img src={ClaraBrazAboutImage} alt="Clara Braz" style={{ width: '100%', borderRadius: 99 }} />
          </Box>
          <Box>
            <Heading
              as="h3"
              fontSize="16px"
              lineHeight="19px"
              fontWeight={400}
              color="secondary.main3"
              textTransform="uppercase"
              mt="32px"
              mb="16px"
            >
              Sobre a Profissional
            </Heading>
            <img
              src={ClaraBrazSpellImage}
              alt="Nome: Clara Braz"
              style={{ width: '100%' }}
            />
            <SocialNetworkButtons width="100%" p="0px" />
          </Box>
        </Box>
        <Box mt="32px">
          <Text fontSize="14px" lineHeight="14px" color="secondary.main3">
            Clara Braz é Psicoterapeuta Transpessoal Sistêmica formada por
            Jordan Campos. O seu trabalho tem como foco o enfrentamento do luto,
            das perdas emocionais e materiais, para uma vida leve, feliz e
            conectada com sua essência. Além disso, atua na resolução de
            problemas com o acesso ao inconsciente trazendo à tona uma clareza
            mental definitiva para superação de traumas, medos, baixa
            autoestima, insegurança e outros.
          </Text>
          <Text fontSize="14px" lineHeight="14px" color="secondary.main3" mt="12px">
            Também é Pós-graduanda em Tanatologia: sobre a morte e o morrer;
            Pós- graduanda em Neurociência e Comportamento. Possui habilitação
            para o uso de Florais de Bach, Iridologia, Constelação Familiar,
            Terapia Regressiva e Programação Neurolinguística (PNL), Tarô
            Terapêutico e Tarô Consciência.
          </Text>
          <Text fontSize="14px" lineHeight="14px" color="secondary.main3" mt="12px">
            Facilitadora de Yoga pela Art of Living Índia e Yoga Dance – SP.
            Instrutora da Arte de Viver Brasil – Curso de Respiração – Happiness
            Program. Sua primeira atuação profissional foi como Advogada. Possui
            quatro pós-graduações nessa área. Trabalhou em diversas empresas
            públicas e privadas na Bahia e Pernambuco, incluindo grande
            escritório trabalhista da Bahia.
          </Text>
          <Text fontSize="14px" lineHeight="14px" color="secondary.main3" mt="12px">
            Após uma grande perda familiar, ela descobriu o caminho profundo do
            autoconhecimento. Daí ela percebeu que deveria se dedicar a ajudar a
            outras pessoas nas suas próprias questões relacionadas ao
            autoconhecimento e luto.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileAbout;
