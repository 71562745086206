import * as React from "react"
import About from "../components/About"
import FAQ from "../components/FAQ"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Services from "../components/Services"
import SocialNetworks from "../components/SocialNetworks"

const IndexPage = () => {
  return (
    <React.Fragment>
      <Hero />
      <Services />
      <About />
      <SocialNetworks />
      <FAQ />
      <Footer />
    </React.Fragment>
  )
}

export default IndexPage
