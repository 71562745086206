import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const textProps = {
  fontSize: '18px',
  lineHeight: '18px',
  textAlign: 'justify',
  color: 'secondary.main3',
} as TextProps;

export default [
  {
    question: 'Por que optar pela Psicoterapia Transpessoal Sistêmica?',
    answer: (
      <Text {...textProps}>A Terapia Transpessoal Sistêmica tem por objetivo ir direto ao ponto. Apresenta-se ferramentas integrativas variadas para ajudar o cliente naquela questão levantada.</Text>
    ),
  },
  {
    question: 'Qual a duração de um trabalho de Constelação Familiar?',
    answer: (
      <Text {...textProps}>A sessão de Constelação Familiar dura em torno de 1h.</Text>
    ),
  },
  {
    question: 'O tarô terapeutico é misticismo ou advinhação?',
    answer: (
        <Text {...textProps}>O Tarô Terapêutico é uma forma muito interesse de Autoconhecimento. Acessamos o inconsciente coletivo e, por sincronicidade, as cartas chegam até você! Dessa forma, podemos observar padrões, atitudes e nossas ações com outro olhar, observando de fora da situação. Deste modo, podemos tomar decisões mais conscientes e assertivas. Não é adivinhação, ou misticismo.</Text>
    ),
  },
  {
    question: 'O mapa tarológico é válido por quanto tempo?',
    answer: (
      <Text {...textProps}>O Mapa Tarológico vale por 3 meses.</Text>
    ),
  },
  {
    question: 'O Yoga dance trás os mesmos benefícios do yoga convencional?',
    answer: (
      <React.Fragment>
        <Text {...textProps}>O Yoga Dance traz o conhecimento sagrado do Yoga, aliado a leveza e ludicidade da dança. É um método diferente do Yoga. 
O Yoga e a Dança oferecem uma forma de explorar a autoconsciência. Através dessa prática você será capaz de:</Text>
        <Text as="ul" {...textProps} margin="20px" display="flex" flexDirection="column" gap="8px">
          <li>Redefinir o seu olhar;</li>
          <li>Ampliar seus conceitos acerca do Yoga e da Dança;</li>
          <li>Viver o Yoga ao invés de apenas praticá-lo no tapetinho;</li>
          <li>Tomar posse do seu poder pessoal e brilho interno;</li>
          <li>Cultivar mais entusiasmo, de estar mais vivo, feliz e confiante em seu corpo;</li>
          <li>Ter um aumento na energia, na flexibilidade, no tônus muscular e na saúde cardiovascular;</li>
          <li>Expandir o prana (energia vital) e explorar as infinitas possibilidades de cada momento e movimento;</li>
          <li>Atingir equilíbrio e conexão em todos os aspectos; físico, energético, emocional, mental.</li>
        </Text>
      </React.Fragment>
    ),
  },
]