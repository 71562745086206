import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Container } from '@chakra-ui/react';
import React from 'react';
import SectionTitle from '../SectionTitle';

import ALL_QUESTIONS from './questions';

const FAQ: React.FC = () => {
  return (
    <Box bg="#FFF4F6" pt="40px" pb="60px">
      <Container maxW="container.md">
        <SectionTitle title="Dúvidas Frequentes" />
        <Box mt="32px">
          <Accordion allowMultiple>
            {ALL_QUESTIONS.map(({ question, answer }) => (
              <AccordionItem key={question} bg="white" border="0px" mb="12px" boxShadow="0px 4px 17px rgba(0, 0, 0, 0.05)" borderRadius="8px">
                <AccordionButton  borderRadius="4px" _focus={{ boxShadow: 'none' }} padding="16px 22px">
                  <Box flex={1} textAlign="left" color="secondary.main3" fontSize="18px" lineHeight="21px" fontWeight={700}>{question}</Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel padding="20px">{answer}</AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}

export default FAQ;