import { useMediaQuery } from "@chakra-ui/react";
import React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

const Footer = () => {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  return isMobile ? <FooterMobile /> : <FooterDesktop />
};

export default Footer;
