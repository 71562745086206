import { useMediaQuery } from "@chakra-ui/react";
import React from "react";

import { openWhatsApp } from "../../utils";
import DesktopHero from "./DesktopHero";
import MobileHero from "./MobileHero";

const Hero = () => {
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  return isMobile ? <MobileHero /> : <DesktopHero />;
};

export default Hero;
