const PHONE_NUMBER = 5571993055395

export const openWhatsApp = () => {
  const text = 'Olá, gostaria de saber mais sobre seus atendimentos';
  const searchParams = new URLSearchParams({
    text,
    phone: String(PHONE_NUMBER),
  })
  const url = new URL(`https://api.whatsapp.com/send?${searchParams.toString()}`);
  window.open(url)
}

export const openInstagram = () => {
  const url = new URL(`https://instagram.com/clarabraz.terapias`);
  window.open(url)
}

export const createMailtoLink = () => {
  const url = new URL('mailto:clarabraz.terapias@gmail.com')
  return url.toString()
}