import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  CloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { openWhatsApp } from "../../utils";

type Props = {
  title: string;
  description?: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
};

const ServiceModal = ({
  description,
  title,
  isOpen = false,
  onClose = () => {},
}: Props) => {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const modalSize = isMobile ? "90%" : "2xl";
  const padding = isMobile ? "16px" : "24px 60px 40px";
  const spaceClose = isMobile
    ? { top: "20px", right: "16px" }
    : { top: "28px", right: "60px" };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding={padding} maxWidth={modalSize}>
        <ModalHeader
          textAlign="center"
          fontSize="18px"
          lineHeight="21px"
          fontWeight={700}
          color="#3E3727"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton
          borderWidth="3px"
          borderStyle="solid"
          borderColor="primary.main2"
          color="primary.main2"
          borderRadius="99px"
          _focus={{ boxShadow: "none" }}
          padding="8px"
          width="auto"
          height="auto"
          {...spaceClose}
          onClick={onClose}
        />
        <ModalBody>{description}</ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            bg="primary.main3"
            color="white"
            textTransform="uppercase"
            size="md"
            mr={3}
            _hover={{ bg: "primary.main3" }}
            _active={{ bg: "primary.main3" }}
            onClick={openWhatsApp}
          >
            Agendar uma Consulta
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ServiceModal;
