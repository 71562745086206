import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import ClaraBrazImage from "../../assets/images/clara-braz.png";
import WhiteLogo from "../../assets/images/white-logo.svg";
import ClaraBrazBackground from "../../assets/images/bg-clara-braz.svg";

import { openWhatsApp } from "../../utils";

const MobileHero = () => {
  return (
    <Box bg="primary.300" width="100%" paddingTop={20} position="relative">
      <Box
        display="flex"
        justifyContent="flex-end"
        position="relative"
        zIndex={19}
      >
        <Center>
          <Image
            src={ClaraBrazBackground}
            alt="Fundo atrás de Clara Braz"
            position="absolute"
            bottom={0}
            maxW="90%"
          />
          <img
            src={ClaraBrazImage}
            alt="Foto de Clara Braz"
            style={{ width: 560, maxWidth: "80%", height: "auto", zIndex: 18 }}
          />
        </Center>
      </Box>
      <Box bg="#8A5360" position="relative" mt="-30px" zIndex={99} pt="24px" pb="24px">
        <Box
          bgGradient="linear(to-b, rgba(138, 83, 96, 0.0), #8A5360 100%)"
          position="absolute"
          top="-50px"
          w="100%"
          height="50px"
        />
        <Center display="flex" flexDirection="column" pl="20px" pr="20px">
          <img src={WhiteLogo} alt="Logomarca de Clara Braz" style={{ maxWidth: '70%', width: '100%' }} />
          <Heading
            as="h1"
            fontSize="29px"
            color="white"
            lineHeight="29px"
            fontWeight={400}
            textAlign="center"
            mt="24px"
            mb="24px"
          >
            Psicoterapia para<br />ressignficar suas perdas
          </Heading>
          <Text variant="p" color="white" fontSize="16px" lineHeight="18px" textAlign="center">
            Psicoterapia para ressignifcar suas vivências. Venha
            aproveitar os benefícios de diversas ferramentas terapeuticas em
            prol do auto conhecimento, saúde física e mental.
          </Text>
          <Button
            bg="secondary.main2"
            color="white"
            alignSelf="center"
            mt="32px"
            textTransform="uppercase"
            p="16px 40px"
            _hover={{ bg: "seconday.main2" }}
            _active={{ bg: "secondary.main" }}
            onClick={openWhatsApp}
          >
            Agendar uma Consulta
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export default MobileHero;
