import { Box, Container, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { MdLocationOn as LocationIcon } from "@react-icons/all-files/md/MdLocationOn";
import SectionTitle from "../SectionTitle";
import SocialNetworkButtons from "../SocialNetworkButtons";

const SocialNetworks = () => {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const titleSize = isMobile
    ? { lineHeight: "19px", fontSize: "18px" }
    : { lineHeight: "32px", fontSize: "28px" };
  return (
    <Box bg="#FFF4F6" pt="40px" pb="60px">
      <Container
        maxW="container.xxl"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <SectionTitle
          title="Onde encontrar Clara Braz?"
          subtitle="Psicoterapia Online"
        />
        <Box display="flex" alignItems="center" gap="8px" mt="42px">
          <Box
            bg="secondary.main"
            borderRadius="99px"
            color="white"
            padding="12px"
          >
            <LocationIcon size={32} />
          </Box>
          <Text fontSize="18px" lineHeight="18px" color="secondary.main3" textAlign="justify">
            Os atendimentos são realizados de forma online se encaixando na sua realidade.
          </Text>
        </Box>
        <Box>
          <Heading
            as="h4"
            {...titleSize}
            color="secondary.main3"
            fontWeight={500}
            textAlign="center"
            mt="32px" 
          >
            Redes Sociais
          </Heading>
          <SocialNetworkButtons gap="22px" />
        </Box>
      </Container>
    </Box>
  );
};

export default SocialNetworks;
