import { useMediaQuery } from "@chakra-ui/react";
import React from "react";
import MobileAbout from "./MobileAbout";
import DesktopAbout from "./DesktopAbout";

const About = () => {
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  return isMobile ? <MobileAbout /> : <DesktopAbout />;
};

export default About;
