import { Box, Button, Center, Container, Image, Text } from "@chakra-ui/react";
import React from "react";
import LogoIcon from "../../assets/images/logo-icon-footer.svg";
import RigelSoftwares from "../../assets/images/rigel-softwares.svg";

const FooterMobile = () => {
  return (
    <Box bg="#FFF4F6">
      <Box w="100%" bg="#E7D09A" borderTopRadius="25px">
        <Container
          maxW="100%"
          padding="32px 12px"
          display="flex"
          flexDirection="column"
          color="primary.main"
        >
          <Center>
            <Text color="#7E6935" fontSize="16px" lineHeight="19px">
              &copy; 2022. Todos os direitos reservados.
            </Text>
          </Center>
          <Box mt="32px" display="flex" justifyContent="space-around" alignItems="center">
            <Image
              src={LogoIcon}
              objectFit="cover"
              htmlWidth="120px"
              htmlHeight="auto"
            />
            <Button
              variant="link"
              color="primary.main"
              _hover={{ textDecoration: "none" }}
              _active={{ color: "primary.main" }}
              onClick={() => {
                window.open("https://rigelsoftwares.com");
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" gap="4px">
                <Text color="#7E6935" fontWeight="normal">
                  Desenvolvido por
                </Text>
                <Image
                  src={RigelSoftwares}
                  objectFit="cover"
                  htmlWidth="120px"
                  htmlHeight="auto"
                />
              </Box>
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default FooterMobile;
