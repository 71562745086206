import { Box, Button, Container, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import ServiceModal from "./ServiceModal";

type Props = {
  title: string;
  image: any;
  description?: React.ReactNode;
};
const ServiceItem = ({ title, image, description }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const titleSize = isMobile
    ? { lineHeight: "19px", fontSize: "16px" }
    : { lineHeight: "21px", fontSize: "18px" };
  return (
    <React.Fragment>
    <Box
      key={title}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="white"
      padding="20px 54px"
      maxW="90%"
      w="288px"
      maxH="219px"
      h="219px"
      borderRadius="8px"
    >
      <Image
        src={image}
        alt={title}
        flexGrow={1}
        objectFit="contain"
        boxSize="100px"
      />
      <Text
        textAlign="center"
        display="flex"
        alignItems="center"
        mt="12px"
        mb="8px"
        color="#3E3727"
        fontWeight={700}
        {...titleSize}
      >
        {title}
      </Text>
      <Button
        variant="link"
        color="primary.main"
        _active={{ color: { color: "primary.main" } }}
        onClick={() => setIsOpen(true)}
      >
        Saiba mais {">"}
      </Button>
    </Box>
    <ServiceModal {...{ title, description, isOpen }} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
};

export default React.memo(ServiceItem);
