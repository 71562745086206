import { Box, Button, Container, Heading, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import ClaraBrazImage from "../../assets/images/clara-braz.png";
import WhiteLogo from "../../assets/images/white-logo.svg";
import ClaraBrazBackground from "../../assets/images/bg-clara-braz.svg";

import { openWhatsApp } from "../../utils";

const DesktopHero = () => {
  return (
    <Box
      bg="primary.main"
      width="100%"
      paddingTop={20}
    >
      <Container maxW={{ md: 'container.xxl', sm: '100%' }}>
        <Box display="flex" flexDirection={{ md: 'row', sm: 'column' }} alignItems="flex-end">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            pt="20px"
            pb="100px"
            flex={1}
          >
            <img src={WhiteLogo} alt="Logomarca de Clara Braz" width={280} />
            <Heading
              as="h1"
              fontSize="h1"
              color="white"
              lineHeight="h1"
              fontWeight={400}
              mt="52px"
              mb="20px"
            >
              Psicoterapia para<br />ressignficar suas perdas
            </Heading>
            <Text variant="p" color="white" fontSize="18px">
              Psicoterapia para ressignifcar suas vivências. Venha
              aproveitar os benefícios<br />de diversas ferramentas terapeuticas em
              prol do auto conhecimento, saúde física e mental.
            </Text>
            <Button
              bg="secondary.main2"
              color="white"
              alignSelf="center"
              mt="54px"
              textTransform="uppercase"
              p="16px 40px"
              _hover={{ bg: "seconday.main2" }}
              _active={{ bg: "secondary.main" }}
              position="relative"
              zIndex={20}
              onClick={openWhatsApp}
            >
              Agendar uma Consulta
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            position="relative"
          >
            <Image
              src={ClaraBrazBackground}
              alt="Fundo atrás de Clara Braz"
              position="absolute"
              bottom={0}
              left="50%"
              marginLeft="-300px"
              w="600px"
              maxW="600px"
            />
            <img
              src={ClaraBrazImage}
              alt="Foto de Clara Braz"
              style={{ width: 560, maxWidth: "100%", height: "auto", zIndex: 9 }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DesktopHero;
